import { makeStyles, Tooltip } from '@material-ui/core';

import enums from 'src/utils/enums';

type SourceType = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '10' | '11';
type ChannelType =
  | 'phone'
  | 'inline'
  | 'unknown'
  | 'wechatAccount'
  | 'wechatMiniProgram'
  | 'wechatKf'
  | 'web'
  | 'android'
  | 'imAgent'
  | 'WhatsApp';
type ChannelTypeBackground =
  | 'background-unknown'
  | 'background-wechatAccount'
  | 'background-wechatMiniProgram'
  | 'background-wechatKf'
  | 'background-web'
  | 'background-android';
const defaultColor = {
  color: '#333333',
};
const useStyles = makeStyles({
  sourceWrapper: {
    flexGrow: 1,
    minWidth: 0,
    height: '20px',
    padding: '0 5px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
  },
  inline: defaultColor,
  phone: defaultColor,
  unknown: defaultColor,
  wechatAccount: {
    color: '#ff9c19',
  },
  wechatMiniProgram: {
    color: '#6ec728',
  },
  wechatKf: {
    color: '#006eff',
  },
  web: {
    color: '#1fc0cc',
  },
  android: {
    color: '#006eff',
  },
  imAgent: {
    color: '#2c78de',
  },
  WhatsApp: {
    color: '#2c78de',
  },
  'background-unknown': {
    background: '#f5f5f5',
  },
  'background-wechatAccount': {
    background: 'rgba(255, 156, 25, 0.2)',
  },
  'background-wechatMiniProgram': {
    background: '#e5f7d7',
  },
  'background-wechatKf': {
    background: '#cce2ff',
  },
  'background-web': {
    background: '#d2f2f5',
  },
  'background-android': {
    background: '#cce2ff',
  },
});

export const ChannelSource = ({
  sourceNumber,
  channelName,
  sourceOverride,
}: {
  sourceNumber: SourceType;
  channelName: string;
  sourceOverride?: string;
}) => {
  const classes = useStyles();
  const source = sourceOverride || (enums.peerSource[sourceNumber] as ChannelType);
  const sourceType: Record<SourceType, ChannelType> = {
    0: 'phone',
    1: 'inline',
    2: 'wechatMiniProgram',
    3: 'wechatAccount',
    4: 'web',
    5: 'wechatAccount',
    6: 'wechatMiniProgram',
    7: 'wechatKf',
    8: 'android',
    10: 'imAgent',
    11: 'WhatsApp',
  };
  return (
    <span
      className={`${classes.sourceWrapper} ${
        classes[`background-${sourceType[sourceNumber]}` as ChannelTypeBackground]
      } ${classes[sourceType[sourceNumber]]}`}
    >
      <Tooltip title={`${channelName ? `${channelName}@` : ''}${source}`}>
        <span
          className={classes[sourceType[sourceNumber]]}
          style={{
            textOverflow: 'ellipsis',
            wordBreak: 'break-all',
            overflow: 'hidden',
            display: 'inline-block',
            verticalAlign: 'text-bottom',
          }}
        >
          {channelName && (
            <>
              <span>{channelName}</span>@
            </>
          )}
          {source}
        </span>
      </Tooltip>
    </span>
  );
};
