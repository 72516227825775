import { makeStyles } from '@material-ui/core';
import { isRejected } from '@reduxjs/toolkit';
import { Form, Input, Card, Button, InputAdornment } from '@tencent/tea-component';
import { useField, useForm, FieldRenderProps } from 'react-final-form-hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import VerifyCode from 'src/components/VerifyCode/VerifyCode';

import { loginByCaptcha } from '../../store/slices/userInfo';
import { showError } from '../../utils';

const useStyles = makeStyles({
  card: {
    padding: '80px 120px',
  },
  code: {
    paddingLeft: 20,
  },
  submit: {
    width: 300,
    height: 40,
  },
  action: {
    textAlign: 'center',
  },
});

interface FormValues {
  phone: string;
  code: string;
}

interface LoginByMobileProps {
  loginCallback: () => void;
}

export const LoginByMobile = (props: LoginByMobileProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { form, handleSubmit } = useForm<FormValues>({
    initialValues: {
      phone: '',
      code: '',
    },
    validate: (values) => {
      const errors: {
        [key in keyof FormValues]: any;
      } = {
        phone: undefined,
        code: undefined,
      };
      if (!values.phone) {
        errors.phone = t('请输入手机号');
      }
      if (!values.code) {
        errors.code = t('请输入验证码');
      }
      return errors;
    },
    onSubmit: async (values) => {
      const res = await dispatch(
        loginByCaptcha({
          mobile: values.phone,
          captchaCode: values.code,
        }),
      );
      if (isRejected(res)) {
        showError(res.payload || res.error.message);
        return;
      }
      props.loginCallback();
    },
  });

  const phone = useField<FormValues['phone'], FormValues>('phone', form);
  const code = useField<FormValues['code'], FormValues>('code', form);

  const getFieldStatus = (fieldProps: FieldRenderProps<FormValues['phone'] | FormValues['code'], string>) => {
    if (fieldProps.meta.touched && fieldProps.meta.error) {
      return fieldProps.meta.error;
    }
  };

  return (
    <Card className={classes.card}>
      <Card.Body title={t('请验证用于登录云联络中心的手机号')}>
        <Form>
          <Form.Item
            showStatusIcon={false}
            label={t('手机号')}
            status={getFieldStatus(phone) ? 'error' : undefined}
            message={getFieldStatus(phone)}
          >
            <Input size={'full'} {...phone.input} />
          </Form.Item>
          <Form.Item
            label={t('验证码')}
            showStatusIcon={false}
            status={getFieldStatus(code) ? 'error' : undefined}
            message={getFieldStatus(code)}
          >
            <InputAdornment after={<VerifyCode module={'login'} mobile={phone.input.value} />}>
              <Input {...code.input} />
            </InputAdornment>
          </Form.Item>
        </Form>
        <Form.Action className={classes.action}>
          <Button className={classes.submit} type={'primary'} htmlType={'button'} onClick={() => handleSubmit()}>
            {t('点击验证')}
          </Button>
        </Form.Action>
      </Card.Body>
    </Card>
  );
};
