import { createAsyncThunk } from '@reduxjs/toolkit';

import tccc from 'src/utils/tccc';

export const startRealtimeAsr = createAsyncThunk('sessions/startRealtimeAsr', async (params: { sessionId: string }) => {
  await tccc.Call.startASR({ sessionId: params.sessionId });
  return params;
});

export const stopRealtimeAsr = createAsyncThunk('/sessions/stopRealtimeAsr', async (params: { sessionId: string }) => {
  await tccc.Call.stopASR({ sessionId: params.sessionId });
  return params;
});
