export enum Direction {
  callIn,
  callOut,
}

export enum UseMobileCallOut {
  off,
  on,
}
export enum UseMobileAccept {
  off,
  offlineOnly,
  always,
}
export enum PeerSources {
  phone = '0',
  inline = '1',
  wechatMiniProgramCustmlized = '2',
  wechatOfficialAccount = '3',
  web = '4',
  wechatServiceAccount = '5',
  wechatMiniProgramStadard = '6',
  wechatKf = '7',
  android = '8',
  imAgent = '10',
  WhatsApp = '11',
}
